<template>
<div id="menu">
    <div class="d-flex justify-content-md-center align-items-center">
        <div class="mt-0 mb-0 mt-md-5 mb-md-5 p-1 p-md-0 animate__bounceIn animate__animated">
            <b-img :class="{ 'animate__flip' : avatar_flip }" class="menu-avatar animate__animated animate__faster" center rounded="circle" :src="avatar()"></b-img>
        </div>
        <div class="d-block d-md-none ml-auto p-1">
            <b-button id="menuicon" v-on:click="showMenu()" variant="primary">Meny</b-button>
        </div>
    </div>
    <b-list-group 
    id="menu-nav" class="position-sticky" style="top:10px;" 
    v-bind:class="{ visible: visible }"
    >
        <b-list-group-item :active="isSelected('/hem') || isSelected('/') " to="/hem" v-on:click="click()">
            <b-container>
                <b-row class="justify-content-start align-items-center">
                    <b-col cols="3" class="page-svg">
                        <inline-svg :class="{'animate__animated animate__rubberBand' : isSelected('/hem')}" :src="this.$images.getSvgUrl('home.svg')" width="24" height="24" />
                    </b-col>
                    <b-col cols="9" class="page-title">
                        Hem
                    </b-col>
                </b-row>
            </b-container>
        </b-list-group-item>

        <b-list-group-item :active="isSelected('/scen')" to="/scen" v-on:click="click()">
            <b-container>
                <b-row class="justify-content-start align-items-center">
                    <b-col cols="3" class="page-svg">
                        <inline-svg :class="{'animate__animated animate__rubberBand' : isSelected('/scen')}" :src="this.$images.getSvgUrl('bookmark.svg')" width="24" height="24" />
                    </b-col>
                    <b-col cols="9" class="page-title">
                        Scen
                    </b-col>
                </b-row>
            </b-container>
        </b-list-group-item>

        <b-list-group-item :active="isSelected('/film')" to="/film" v-on:click="click()">
            <b-container>
                <b-row class="justify-content-start align-items-center">
                    <b-col cols="3" class="page-svg">
                        <inline-svg :class="{'animate__animated animate__rubberBand' : isSelected('/film')}"  :src="this.$images.getSvgUrl('video.svg')" width="24" height="24" />
                    </b-col>
                    <b-col cols="9" class="page-title">
                        Film och TV
                    </b-col>
                </b-row>
            </b-container>
        </b-list-group-item>

        <b-list-group-item :active="isSelected('/rost')" to="/rost" v-on:click="click()">
            <b-container>
                <b-row class="justify-content-start align-items-center">
                    <b-col cols="3" class="page-svg">
                        <inline-svg :class="{'animate__animated animate__rubberBand' : isSelected('/rost')}" :src="this.$images.getSvgUrl('mic.svg')" width="24" height="24" />
                    </b-col>
                    <b-col cols="9" class="page-title">
                        Röst och Dubb
                    </b-col>
                </b-row>
            </b-container>
        </b-list-group-item>

        <b-list-group-item :active="isSelected('/improvisation')" to="/improvisation" v-on:click="click()" >
            <b-container>
                <b-row class="justify-content-start align-items-center">
                    <b-col cols="3" class="page-svg">
                        <inline-svg :class="{'animate__animated animate__rubberBand' : isSelected('/improvisation')}" :src="this.$images.getSvgUrl('eye-off.svg')" width="24" height="24" />
                    </b-col>
                    <b-col cols="9" class="page-title">
                        Improvisation
                    </b-col>
                </b-row>
            </b-container>
        </b-list-group-item>

        <b-list-group-item :active="isSelected('/instagram')" to="/instagram" v-on:click="click()">
            <b-container>
                <b-row class="justify-content-start align-items-center">
                    <b-col cols="3" class="page-svg">
                        <inline-svg :class="{'animate__animated animate__rubberBand' : isSelected('/instagram')}" :src="this.$images.getSvgUrl('instagram.svg')" width="24" height="24" />
                    </b-col>
                    <b-col cols="9" class="page-title">
                        Instagram
                    </b-col>
                </b-row>
            </b-container>
        </b-list-group-item>

        <b-list-group-item :active="isSelected('/kontakt')" to="/kontakt" v-on:click="click()">
            <b-container>
                <b-row class="justify-content-start align-items-center">
                    <b-col cols="3" class="page-svg">
                        <inline-svg :class="{'animate__animated animate__rubberBand' : isSelected('/kontakt')}" :src="this.$images.getSvgUrl('mail.svg')" width="24" height="24" />
                    </b-col>
                    <b-col cols="9" class="page-title">
                        Kontakta mig
                    </b-col>
                </b-row>
            </b-container>
        </b-list-group-item>

        <div class="sidebar-footer position-fixed d-none d-md-block" style="bottom:15px">

        
        </div>

    </b-list-group>
</div>
</template>

<script>
export default {
    name: 'Menu',
    data() {
        return {
            visible: false,
            avatar_flip: false
        }
    },
    methods: {
        click()
        {
            this.visible = false;
            this.avatar_flip = true;
            var self = this;
            setTimeout(() =>{
                self.avatar_flip = false;
            }, 1000);
        },
        isSelected(path, startswith) {
            if (startswith) {
                return this.$route.path.startsWith(path);
            } else {
                return path == this.$route.path;
            }
        },
        avatar() {
            return require("@/assets/images/avatar.jpg");
        },
        showMenu() {
            this.visible = !this.visible;
        }
    }
}
</script>
