/* eslint-disable */

import currency from "currency.js";

export default {
      url(url)
      {
            if (!url)
                  url = "";
            const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
            const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
            const p = new RegExp(a.split('').join('|'), 'g')

            return url.toString().toLowerCase()
                  .replace(/\s+/g, '-') // Replace spaces with -
                  .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
                  .replace(/&/g, '-and-') // Replace & with 'and'
                  .replace(/[^\w\-]+/g, '') // Remove all non-word characters
                  .replace(/\-\-+/g, '-') // Replace multiple - with single -
                  .replace(/^-+/, '') // Trim - from start of text
                  .replace(/-+$/, '') // Trim - from end of text
      },
      formatCurrency(number) {
            var sek = value =>
                currency(value, {
                    pattern: `# !`,
                    negativePattern: `-# !`,
                    symbol: "kr",
                    separator: " ",
                    decimal: ","
                });
            return sek(this.round(number)).format();
        },
        // Rounds a number correctly to two decimals:
        // https://stackoverflow.com/questions/10768083/tofixed2-rounds-x-525-inconsistently
        // This method guarantees that if value is more than .005 then it will round up!
        // Example if numberToRound = 5.525 then it will return 5.53
        round(numberToRound) {
            var decimalPlaces = 2;
            var factor = Math.pow(10, decimalPlaces);
            var roundedNumberString = Math.round(numberToRound * factor) / factor;
            var roundedNumber = parseFloat(roundedNumberString);
            return roundedNumber;
        },
};
