
export default {
    getMovie(id, mdata) {
        if (mdata && mdata.Media && mdata.Media.Movies && mdata.Media.Movies[id])
            return mdata.Media.Movies[id];
        return false;
    },
    getAudio(id, mdata) {
        if (mdata && mdata.Media && mdata.Media.Audio && mdata.Media.Audio[id])
            return mdata.Media.Audio[id];
        return false;
    },
    getMediaImage(id, mdata)
    {
        var m = this.getMovie(id, mdata);
        if(m && m.Image)
            return m.Image;
        
        m = this.getAudio(id, mdata);
        if(m && m.Image)
            return m.Image;

        return false;
    }
};
