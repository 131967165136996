import images from "@/helpers/images";
import formatter from "@/helpers/formatter";
import time from "@/helpers/time";
import date from "@/helpers/date";
import instagram from "@/helpers/instagram";
import media from "@/helpers/media";

export default {
    install(Vue) {        
        Vue.prototype.$images = images;
        Vue.prototype.$formatter = formatter;
        Vue.prototype.$time = time;
        Vue.prototype.$date = date;
        Vue.prototype.$instagram = instagram;
        Vue.prototype.$media = media;        
    }
};