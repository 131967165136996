<template>
<div class="page-scen">
    <PageTitle text="Scen" image="003.jpg"></PageTitle>
    <PageContent>
        <b-row>
            <b-col md="6">
                <ContentTitle>Scenen och jag!</ContentTitle>
                <p>Mitt intresse f�r teater har alltid funnits men det var f�rst 2 �r efter gymnasiet som jag tog tag i det ordentligt n�r jag s�kte och kom in p� Wendelsbergs Teater &amp; Skolscen.<br>
                    Innan det hade jag l�st Naturvetenskaplig linje p� gymnasiet och d�refter jobbat i tv� �r med barn och ungdomar.</p>

                <p>V�ren 2006 sedan avslutade jag mitt tredje �r, som var ett p�byggnads�r, p� Wendelsbergs teater amp; skolscen i M�lnlycke.<br>
                    De tv� f�rsta �ren d�r gick jag musikdramatisk inriktning vilket innebar lite mer tyngd p� dans och s�ng, men givetvis mycket sk�despelartr�ning. <br>
                    Det tredje och sista �ret gick i frigruppens tecken. Vi var d� sju elever som tillsammans bildade "Originalteatern".
                    Vi repeterade in en f�rest�llning, "Lilla Boye - om en uppv�xt" som vi sedan turnerade runt med p� skolor, bygdeg�rdar m.m.
                    F�rest�llningen fick vi producera helt sj�lva vilket gav kunskaper och insikt i producent-biten med s�ljande och allt vad det inneb�r. </p>

                Under mitt tredje �r p� Wendelsberg ingick f�ljande moment:
                <ul>
                    <li>Instudering av en f�rest�llning f�r gymnasium/vuxna.</li>
                    <li>Turn� samt administration av denna.</li>
                    <li>Regi- och scenografiansvar f�r skolscenens slutproduktioner.</li>
                    <li>Scenframst�llning, dramatisk r�relse/dans, s�nggestaltning.</li>
                    <li>Pedagogik och metodik (arbete med f�rest�llningens publikkontakt och arbete med deltagare i teaterverksamhet).</li>
                    <li>Teaterteorier, id�er och m�l f�r folkligt teaterarbete.</li>
                    <li>Studier vid skolans filial i Paris (grekisk tragedi, maskspel och commedia dell�arte).</li>
                </ul>
                <p>
                    Under utbildningen dansades �ven Modern Jazz, Stepp och Balett. <br>
                    Har ocks� haft en del Akrobatisk tr�ning.
                </p>
                Har �ven ocks� l�st f�ljande kurser:
                <ul>
                    <li>Karakt�r - att bygga en karakt�r</li>
                    <li>Clownteknik</li>
                </ul>

                Senaste �ren s� har "Astrid Lindgrens V�rld" varit lite av min fasta scen. En underbar arbetsplats som �r b�de stimulerande och utvecklande.
            </b-col>
            <b-col md="6">
               <h3>Brancherfarenhet i urval</h3>
                <ul class="list-unstyled">
                    <b-media v-for="(item, index) in data" :key="index" tag="li" class="mb-2 pb-2">

                        <h5 class="mt-0 mb-1">{{item.Title}} <span class="font-italic">- {{item.Year}}</span></h5>
                        <div class="mt-2">
                            <span class="font-weight-bold">Roll:</span> <span class="font-italic">{{item.Roll}}</span>
                        </div>
                        <div class="mb-2">
                            <span class="font-weight-bold">Uppsatt av:</span> <span class="font-italic">{{item.Employer}}</span>
                        </div>
                        <hr>
                    </b-media>
                </ul>
            </b-col>
        </b-row>
    </PageContent>
</div>
</template>

<script>
import json from '@/data/johancarlberg_scen.json';
import PageTitle from "@/components/common/PageTitle";
import PageContent from "@/components/common/PageContent";
import ContentTitle from "@/components/common/ContentTitle";

export default {
    name: 'Scen',
    components: {
        PageTitle, PageContent, ContentTitle
    },
    data() {
        return {
            data: json.data
        };
    },
    mounted() {

    },
    methods: {

    }
};
</script>
