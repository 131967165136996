<template>
<div class="page-home">
    <PageTitle text="johancarlberg.se" image="002.jpg"></PageTitle>
    <PageContent>
        <ContentTitle>Hej och välkommen!</ContentTitle>

        <b-row>
            <b-col>
                <p><span class="font-italic font-large">Johan Carlberg</span> heter jag.
                    En {{age}} år ung pappa, pojkvän skådespelare, improvisatör och orddiktare mm.
                </p>
                <p>
                    Född och uppvuxen i Mölndal i en stor familj på åtta personer plus katter.
                    Bor numera på Guldheden, i Göteborg, med min sambo Line och våra barn Harry och Majken.
                </p>
                <p>
                    Jag försörjer mig som frilansande skådespelare.<br>
                    Ett yrkesval som gett mig tillfälle att spela teater på is inför ett näst
                    intill fullsatt globen, representera Sverige på världsutställningen i
                    Sydkorea samt försökt spela en allvarlig mordhistoria framför en man som var så
                    full att han tar upp en krycka och "skjuter" mot oss skådespelare och andra i publiken.
                </p>

                <p class="font-italic">
                    Kort sagt - ett yrke med variation. </p>
            </b-col>
            <b-col>
                <b-img :src="homeimg" fluid></b-img>
            </b-col>
        </b-row>
    </PageContent>
</div>
</template>

<script>
import PageTitle from "@/components/common/PageTitle";
import PageContent from "@/components/common/PageContent";
import ContentTitle from "@/components/common/ContentTitle";

export default {
    name: 'Home',
    components: {
        PageTitle, PageContent, ContentTitle
    },
    data() {
        return {
            test: "hej hej {{age}} <span class='text-primary'>text</span>"
        };
    },
    computed: {
        age() {
            return new Date().getFullYear() - 1982;
        },
        homeimg() {
            return require("@/assets/images/home2.jpg");
        }
    },
    methods: {

    }
};
</script>
