<template>
<div class="page-film">
    <PageTitle text="Film och TV" image="009.jpg"></PageTitle>
    <PageContent>
        <ContentTitle>Mitt liv på vita duken!</ContentTitle>
        <div>
            <p>
                Film är ett fascinerande medium. Det går att uttrycka så mycket med så små medel.
                Jag har haft ynnesten att f� filma lite under �ren och varje g�ng jag medverkat i n�got projekt s� l�mnar jag det med en stark eftersmak av: mera!
            </p>
            <p>
                Jag gick en kurs i G�teborg, annordnad av Trappan. "Grundkurs i Filmsk�despeleri" med Gorki Glaser M�ller.
                D�r gick vi igenom grunderna och b�rjade med minimala uttryck: neutrum. Sedan �kade vi stegvis uttycken och spelet.
                En v�ldigt inspirerande och l�rorik kurs som verkligen gav blodad tand.
            </p>
        </div>
        <b-row v-for="(item, index) in data" :key="index" class="mb-3">
            <b-col sm="3" class="overflow-hidden">
                <b-img class="w-100 img-fluid" v-if="MediaImage(item.MediaId)" :alt="item.Image" :src="MediaImage(item.MediaId)"></b-img>
                <b-img v-else blank blank-color="#abc" class="w-100 img-fluid" alt="placeholder"></b-img>
            </b-col>
            <b-col sm="9">
                <h5 class="mt-0 mb-1">{{item.Title}}</h5>
                <b-row>
                    <b-col lg="9">
                        {{item.Text}}
                    </b-col>
                    <b-col lg="3" class="mt-1 mt-lg-0 align-self-lg-end text-right ">
                        <b-button variant="primary" v-on:click="ShowMovie(item)">Se klipp</b-button>
                    </b-col>
                </b-row>
            </b-col>
            <b-col class="pt-2">
                <hr>
            </b-col>
        </b-row>
        
    </PageContent>
    <b-modal size="lg" ref="MediaModal" :title="ModalTitle" centered>
        <MovieItem :item="currentmedia"></MovieItem>
        <template v-slot:modal-footer="{cancel}"> 
            <b-button variant="primary" @click="cancel()">Stäng</b-button>
        </template>
    </b-modal>
</div>
</template>

<script>
import json from '@/data/johancarlberg_movie.json';
import mediajson from '@/data/johancarlberg_media.json';
import PageTitle from "@/components/common/PageTitle";
import PageContent from "@/components/common/PageContent";
import ContentTitle from "@/components/common/ContentTitle";
import MovieItem from "@/components/media/Movie";

export default {
    name: 'Movie',
    components: {
        PageTitle,
        PageContent,
        ContentTitle,
        MovieItem
    },
    data() {
        return {
            data: json,
            media: mediajson,
            currentmedia: false,
            currentmovie: false
        };
    },
    computed: {
        ModalTitle() {
            if (this.currentmovie && this.currentmovie.Title)
                return this.currentmovie.Title;
            return "";
        }
    },
    methods: {
        ShowMovie(item) {
            this.currentmovie = item;
            var m = this.GetMedia(item.MediaId);
            this.currentmedia = m;
            this.$refs.MediaModal.show();
        },
        MediaImage(id) {
            var path = this.$media.getMediaImage(id, this.media);
            return this.$images.getImage(path);
        },
        GetMedia(id) {
            return this.$media.getMovie(id, this.media);
        }
    }
};
</script>
