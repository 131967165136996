export default {
      likes(item)
      {
            return item.edge_media_preview_like.count;
      },
      comments(item)
      {
            return item.edge_media_to_comment.count;
      },
      caption(item) {
            return item.edge_media_to_caption.edges[0].node.text.replaceAll("\n", "<br>");
      },
      timetaken(item) {
            var tstamp = item.taken_at_timestamp;
            var jsdate = new Date(tstamp * 1000);
            return jsdate;
      },
      username(item) {            
            return item.owner.username;            
      },
      fullwidth(item, index)
      {            
            if (item.display_resources.length >= index)
                  return item.display_resources[index];
            return false;
      },
      thumbnail(item) {
            if (item.thumbnail_resources.length >= 3)
                  return item.thumbnail_resources[2];
            else if (item.thumbnail_resources.length >= 2)
                  return item.thumbnail_resources[1];
            else
                  return item.thumbnail_resources[0];
      },
};
