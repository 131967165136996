<template>
<div class="media-item">
    <audio ref="player" controls class="w-100" controlsList="nodownload">
        <source :src="url" type="audio/mpeg">        
    </audio>
    <p>
        {{item.Text}}
    </p>
</div>
</template>

<script>
export default {
    name: 'AudioItem',
    components: {},
    props: ["item"],
    data() {
        return {

        };
    },
    computed: {
        poster() {
            if (this.item.Image)
                return this.$images.getImage(this.item.Image);
            return "";
        },
        url() {
            if (this.item.Source == "asset")
                return require('@/assets/audio/' + this.item.URL);
            else
                return this.item.URL;
        }
    },
    mounted()
    {
        this.$refs.player.play();
    },
    methods: {

    }
};
</script>
