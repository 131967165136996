<template>
<div class="instagram">
    <PageTitle text="Instagram"></PageTitle>
    <PageContent>
        <div v-if="loading">
            <Loader loadingtext="Läser instagram..."></Loader>
        </div>
        <div v-else>
            <div v-if="completed">
                <div class="d-flex flex-wrap justify-content-center flex-shrink-1 flex-grow-1">
                    <InstagramItem v-on:clicked="showinstagramlarge" v-for="item in data" :key="item.node.id" :item="item.node"></InstagramItem>
                </div>
                <b-modal ref="instagrammodal" :title="modaltitle" size="xl" scrollable centered hide-footer>

                    <div id="instagram-modal">
                        <InstagramLarge :item="previewitem"></InstagramLarge>
                    </div>
                </b-modal>
            </div>
        </div>
    </PageContent>
</div>
</template>

<script>
const Loader = () => import('@/components/common/Loader');
const InstagramItem = () => import('@/components/instagram/InstagramItem');
const InstagramLarge = () => import('@/components/instagram/InstagramLarge');
import EasyInstaFeed from "easy-instagram-feed";
import PageTitle from "@/components/common/PageTitle";
import PageContent from "@/components/common/PageContent";

export default {
    name: 'Instagram',
    components: {
        InstagramItem,
        InstagramLarge,
        Loader,
        EasyInstaFeed,
        PageTitle,
        PageContent
    },
    data() {
        return {
            loading: true,
            hash: "003056d32c2554def87228bc3fd9668a",
            userid: "347528033",
            photos: 10000,
            completed: false,
            data: null,
            previewitem: null
        };
    },
    computed: {
        username() {
            if (this.data && this.data.length > 0)
                return this.$instagram.username(this.data[0].node);
            return "";
        },
        modaltitle() {
            if (this.previewitem) {
                var adate = this.$instagram.timetaken(this.previewitem);
                var datestring = this.$date.dateToDateWithoutHour(adate);
                return this.$date.stringToReadableFormat(datestring);
            }
            return "";
        }
    },
    mounted() {
        this.loading = true;
        EasyInstaFeed("https://www.instagram.com/graphql/query/?query_hash=" + this.hash + "&variables={\"id\": \"" + this.userid + "\", \"first\": \"" + this.photos + "\"}").then(
            (data) => {
                this.loading = false;
                this.data = data;
                this.completed = true;
            });

    },
    methods: {
        showinstagramlarge(item) {
            this.previewitem = item;
            this.$refs.instagrammodal.show();
        }

    }
};
</script>
