<template>
<div id="app" class="d-flex align-items-stretch align-content-stretch flex-column flex-md-row">
    <Menu></Menu>
    <div id="content">
        <div class="page">
            <router-view />
        </div>
    </div>
</div>
</template>

<script>
import Menu from "@/components/common/Sidebar.vue";

export default {
    name: 'App',
    components: {
        Menu
    },
    data() {
        return {
            visible: true
        }
    },
    methods: {

    }
}
</script>

<style lang="scss">
@import 'styles/main.scss';
</style>
