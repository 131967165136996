<template>
<div class="media-item">
    <b-embed type="iframe" aspect="16by9" :src="item.URL"  controls :poster="poster" allowfullscreen>
    </b-embed>
    <p>
        {{item.Text}}
        </p>
</div>
</template>

<script>
export default {
    name: 'MovieItem',
    components: {},
    props: ["item"],
    data() {
        return {
            
        };
    },
    computed: {
        poster() {
            if(this.item.Image)
                return this.$images.getImage(this.item.Image);
            return "";
        },
        
    },
    methods: {
      
    }
};
</script>
