<template>
<div class="content-title">
    <slot></slot>
</div>
</template>

<script>
export default {
    name: 'ContentTitle',
}
</script>
