<template>
<div >
    <div v-if="getimage()" style="position:relative;">        
        <b-img class="w-100 pageimage animate__animated animate__fadeIn" :src="getimage()"></b-img>  
        <div class="page-image-title animate__animated animate__zoomInDown">{{text}}</div>     
    </div>
    <div v-else class="pagetitle">
        {{text}}
    </div>
</div>
</template>

<script>
export default {
    name: 'PageTitle',
    props: ["text", "image"],
    methods: {
        getimage() {
            if (this.image)
                return this.$images.getHeaderImage(this.image);
            else
                return false;
        }
    }
}
</script>
