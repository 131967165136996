import Vue from 'vue'
import App from './App.vue'
import { router } from './router';
//import store from './store';
//import Vuex from 'vuex';

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

// SVG inline
import InlineSvg from 'vue-inline-svg';
Vue.component('inline-svg', InlineSvg);


// plugins/helpers
import plugins from "./plugins/helpers";
Vue.use(plugins);

//Settings.defaultLocale = 'se';

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
