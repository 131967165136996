export default {
      getSvgUrl(svgImg) {
            const url = require("@/assets/svg/" + svgImg);
            return url;
      },
      getImage(path) {
            let url = require('@/assets/images/' + path);
            return url;
      },
      getHeaderImage(id) {
            let url = require('@/assets/images/headers/' + id);
            return url;
      },
      getFailedProductImage() {
            let url = '/images/products/default.png';
            return url;
      },
};
