import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home.vue';
import Scen from './views/Scen.vue';
import Film from './views/Film.vue';
import Rost from './views/Rost.vue';
import Impro from './views/Impro.vue';
import Instagram from './views/Instagram.vue';

Vue.use(Router);

export const router = new Router({
  mode: 'history',
  //base: "/jochen/",
  routes: [
    {
      path: '/',
      name: 'hem',
      component: Home
    },
    {
      path: '/hem',
      component: Home
    },
    {
      path: '/scen',
      component: Scen
    },
    {
      path: '/film',
      component: Film
    },
    {
      path: '/rost',
      component: Rost
    },
    {
      path: '/improvisation',
      component: Impro
    },
    {
      path: '/instagram',
      component: Instagram
    }
  ]
});

// router.beforeEach((to, from, next) => {
//   const publicPages = ['/login', '/register', '/home'];
//   const authRequired = !publicPages.includes(to.path);
//   const loggedIn = localStorage.getItem('user');

//   // trying to access a restricted page + not logged in
//   // redirect to login page
//   if (authRequired && !loggedIn) {
//     next('/login');
//   } else {
//     next();
//   }
// });
