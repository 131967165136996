<template>
<div class="page-rost">
    <PageTitle text="Röst och Dubb" image="007.jpg"></PageTitle>

    <PageContent>
        <ContentTitle>Jochen med rösten!</ContentTitle>
        <div>
            <p>
                Senaste �ren har jag samlat p� mig stor erfarenhet som r�stsk�despelare. Jag har f�tt chansen att pr�va dubbing, live action, radio och tv-reklam, voice over och informationsfilmer.<br />
                Jag jobbar ocks� p� dubbf�retaget Eskimo Avenue i G�teborg. D�r g�r jag b�de r�stjobb, �vers�tter manus, projektleder och jobbar som inspelningstekniker/regiss�r.<br>
                Numera kan jag ocks� titulera mig "Hall�a" Jag �r n�mligen den nya manliga presentat�ren p� Discovery Channel. S� 5 dagar �t g�ngen �r det jag som lotsar tittarna genom kv�llarna.
            </p>
        </div>

        <b-row v-for="(item, index) in data" :key="index" class="mb-3">
            <b-col sm="3" class="overflow-hidden">
                <b-img class="w-100 img-fluid" v-if="MediaImage(item.MediaId)" :alt="item.Image" :src="MediaImage(item.MediaId)"></b-img>
                <b-img v-else blank blank-color="#abc" class="w-100 img-fluid" alt="placeholder"></b-img>
            </b-col>
            <b-col sm="9">
                <h5 class="mt-0 mb-1">{{item.Title}}</h5>
                <b-row>
                    <b-col lg="9">
                        {{item.Text}}
                    </b-col>
                    <b-col lg="3" class="mt-1 mt-lg-0 align-self-lg-end text-right ">
                        <b-button v-if="item.Type == 'Audio'" variant="primary" v-on:click="PlayAudio(item)">Lyssna på klipp</b-button>
                        <b-button v-else-if="item.Type == 'Movie'" variant="primary" v-on:click="PlayMvie(item)">Se klipp</b-button>
                    </b-col>
                </b-row>
            </b-col>
            <b-col class="pt-2">
                <hr>
            </b-col>
        </b-row>
    </PageContent>

    <b-modal size="lg" ref="MediaModal" :title="ModalTitle" centered>
        <MovieItem v-if="currentmovie" :item="currentmedia"></MovieItem>
        <AudioItem v-if="currentaudio" :item="currentmedia"></AudioItem>
        <template v-slot:modal-footer="{cancel}"> 
            <b-button variant="primary" @click="cancel()">Stäng</b-button>
        </template>
    </b-modal>
</div>
</template>

<script>
import json from '@/data/johancarlberg_rost.json';
import mediajson from '@/data/johancarlberg_media.json';
import PageTitle from "@/components/common/PageTitle";
import PageContent from "@/components/common/PageContent";
import ContentTitle from "@/components/common/ContentTitle";
import MovieItem from "@/components/media/Movie";
import AudioItem from "@/components/media/Audio";

export default {
    name: 'Rost',
    components: {
        PageTitle,
        MovieItem,
        AudioItem,
        PageContent,
        ContentTitle
    },
    data() {
        return {
            data: json,
            media: mediajson,
            currentmedia: false,
            currentmovie: false,
            currentaudio: false
        };
    },
    computed: {
        ModalTitle() {
            if (this.currentmovie && this.currentmovie.Title)
                return this.currentmovie.Title;
            else if (this.currentaudio && this.currentaudio.Title)
                return this.currentaudio.Title;
            return "";
        }
    },
    methods: {
        PlayAudio(item) {
            this.currentaudio = item;
            this.currentmovie = false;
            var m = this.GetMedia(item.MediaId, item.Type);
            this.currentmedia = m;
            this.$refs.MediaModal.show();
        },
        ShowMovie(item) {
            this.currentaudio = false;
            this.currentmovie = item;
            var m = this.GetMedia(item.MediaId, item.Type);
            this.currentmedia = m;
            this.$refs.MediaModal.show();
        },
        MediaImage(id) {
            var path = this.$media.getMediaImage(id, this.media);
            if (path)
                return this.$images.getImage(path);
            return false;
        },
        GetMedia(id, type) {
            if (type == "Audio")
                return this.$media.getAudio(id, this.media);
            else
                return this.$media.getMovie(id, this.media);
        }
    }
};
</script>
