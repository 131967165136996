<template>
<div class="page-content animate__animated animate__zoomInUp ">
    <slot></slot>
</div>
</template>

<script>
export default {
    name: 'PageContent'
}
</script>
